<template>
	<div class="wrapper">
		<div class="view" ref="view" @click="growImg($event)"></div>
		<div class="select">
			<div class="uploadvideo">
				<span class="el-icon-picture" @click="openUpload" title="上传图片"></span>
			</div>
			<div class="uploadvideo">
				<input type="file" ref="upvideo" @change="onupdatevideo" accept=".mp4" title="上传视频">
				<span class="el-icon-video-camera"></span>
			</div>
			<div  class="uploadvideo">
				<input type="file" ref="file" @change="onupdatefile" accept=".doc,.ppt,.pdf,.xls,.txt">
				<span class="el-icon-folder"></span>
			</div>
			<template>
				<el-upload v-show="imgUrl != ''" :action="imageUrl" accept=".png,.jpg" list-type="picture-card" :on-success="UserImgSuccess"  :on-remove="UserImgtRemove">
					<i class="el-icon-plus"></i>
				</el-upload>
			</template>
		</div>
		<div class="input">
			<textarea :disabled="disabled" @keydown="entrySend($event)" ref="text" rows="5" name="S1" cols="20" />
			<button @click="sendText">发送</button>
    </div>
    <div class="mask" v-if="showImgWrap">
      <img :src="showImgUrl" alt />
      <i class="el-icon-close close" @click="closeImg"></i>
    </div>
  </div>
</template>

<script>
import { usergetinfo } from "@/request/user";
import { mapState } from "vuex";
export default {
  props: ["inquiry_doctor_id", "inquiry_id"],
  data() {
    return {
      imgUrl: "",
      isShow: true,
      disabled: false,
      showImgUrl: "",
      showImgWrap: false,
      userMessage: {}, // 医生药师传递的信息
      preMessageText: "",
      preImgUrl: "",
	  userid:"",
	  video:"",
	  isfile:"",
    };
  },
  created() {
    usergetinfo({ token: this.$token }).then(res => {
      if (res.code == 1) {
		  console.log(res);
		  this.userid=res.data.id;
        if (res.data.avatar) {
          this.myAvatar = res.data.avatar;
        } else {
          this.myAvatar = require("@/assets/image/user.png");
        }
      } else {
        this.myAvatar = require("@/assets/image/user.png");
      }
    });
    let chat_in = {
      method: "chat_in",
      data: {
        inquiry_doctor_id: this.inquiry_doctor_id,
        inquiry_id: this.inquiry_id
      }
    };
    this.$store.state.sendMessage(JSON.stringify(chat_in));
  },
  watch: {
	  
    retMessage() {
      // 直接监听store中的数据变化
       let retMessage = this.$store.state.retMessage;
	   this.userMessage = retMessage;
	   console.log(retMessage);
	   if(retMessage.user_info.user_id!=this.userid){
		     this.userTransmitMessage();
	   }
    }
  },
  computed: {
    ...mapState(["doctor_info", "retMessage"])
  },
  methods: {
	 onupdatevideo(){
		let formData = new FormData();
		console.log( this.$refs.upvideo.files[0]);
		formData.append("file", this.$refs.upvideo.files[0]);
		formData.append("token", this.$token);
		 this.$axios.post("http://hlwyaodian.jzbwlkj.com/api/file/upload",formData).then(res=>{
			 if(res.data.code==1){
				 this.video=res.data.data.file.url;
				  console.log(this.video); 
				  this.sendvideo();
			 }
			
		 });
		
	 },
	 onupdatefile(){
	 		let formData = new FormData();
	 		formData.append("file", this.$refs.file.files[0]);
	 		formData.append("token", this.$token);
	 		 this.$axios.post("http://hlwyaodian.jzbwlkj.com/api/file/upload",formData).then(res=>{
	 			 if(res.data.code==1){
	 				 this.isfile=res.data.data.file; 
	 				  this.sendfile();
	 			 }
	 		 });
	 		
	 },
	 
    sendText() {
      if (this.imgUrl != "") {
        this.sendImg();
      }
	  if(this.video!=""){
		  this.sendvideo();
	  }
      if (this.$refs.text.value.trim() == "") return;

      this.sendMessage(this.$refs.text.value);
      // 读取img图片的数据
      let div = document.createElement("div");
      div.innerHTML = `<div> <img  src="${this.myAvatar}"/>
                           <span> ${this.$refs.text.value} </span> </div>`;
      div.className = "doctor chatbox user1";
      this.$refs.view.appendChild(div);
      this.$refs.text.value = "";
      this.$refs.view.scrollTop = this.$refs.view.scrollHeight;
    },
    sendImg() {
      this.sendMessage(this.imgUrl);
      let div = document.createElement("div");
      div.innerHTML = `<div> <img  src="${this.myAvatar}"/>
                           <span class='img'> <img src='${this.imgUrl}'/> </span> </div>`;
      div.className = "doctor chatbox user1";
      this.$refs.view.appendChild(div);
      this.$refs.view.scrollTop = this.$refs.view.scrollHeight;
      this.imgUrl = ''
    },
	sendvideo() {
	  this.sendMessage(this.video);
	  let div = document.createElement("div");
	  div.innerHTML = `<div> <img  src="${this.myAvatar}"/>
	                       <span class='video'> <video src='${this.video}' controls="controls"/> </span> </div>`;
	  div.className = "doctor chatbox user1";
	  this.$refs.view.appendChild(div);
	  this.$refs.view.scrollTop = this.$refs.view.scrollHeight;
	  this.video = ''
	},
	sendfile(){
		let url=this.isfile.url+"?file_name="+this.isfile.name;
		this.sendMessage(url);
		let div = document.createElement("div");
		div.innerHTML = `<div class="isfile">
		                      <img  src="${this.myAvatar}"/>
		                       <span class='isfiles'>
							        <i class="el-icon-document"></i>${this.isfile.name}
							  </span> 
						</div>`;
		div.className = "doctor chatbox user1";
		this.$refs.view.appendChild(div);
		this.$refs.view.scrollTop = this.$refs.view.scrollHeight;
		this.isfile = ''
	},
	entrySend(e) {
      if (this.$refs.text.value.trim() == "") return;
      if (e.keyCode === 13) {
        this.sendText();
      }
    },
    UserImgSuccess(response) {
      this.imgUrl = response.data.file.url;
    },
    UserImgtRemove() {
      this.imgUrl = "";
    },
    openUpload() {
      this.imgUrl = "";
      let div = document.getElementsByClassName("el-upload--picture-card")[0];
      div.click();
    },
    // 接收传递的信息
    userTransmitMessage() {
      let avatar;
	  console.log(this.userMessage);
      if (this.userMessage.user_info && this.userMessage.user_info.avatar) {
        avatar = this.userMessage.user_info.avatar;
      } else {
        avatar = require("@/assets/image/Interrogation_expert_Headportrait@2x.png");
      }
      if (this.userMessage.pic!=""&&this.userMessage.pic!=undefined) {
        // 传递的是图片
        let div = document.createElement("div");
        div.innerHTML = `<div> <img  src="${avatar}"/>
                           <span class='img'> <img src='${this.userMessage.pic}'/> </span> </div>`;
        div.className = "user chatbox";
        this.$refs.view.appendChild(div);
      } else if(this.userMessage.video!=""&&this.userMessage.video!=undefined) {
		  // 传递的是视频
		  let div = document.createElement("div");
		  div.innerHTML = `<div> <img  src="${avatar}"/>
		                     <span>  <video src='${this.userMessage.video}' controls="controls"/></span> </div>`;
		  div.className = "user chatbox";
		  this.$refs.view.appendChild(div);
	  }else if(this.userMessage.file!="" &&this.userMessage.file!=undefined){
		  // 传递的是文件
		  let name=this.userMessage.file.slice(this.userMessage.file.indexOf("?file_name=")+1,this.userMessage.file.length);
		  let url=this.userMessage.file.slice(0,this.userMessage.file.indexOf("?file_name="));
		  let div = document.createElement("div");
		  div.innerHTML = `<div> <img  src="${avatar}"/>
		                     <span>
							 <a href="${url}" download="${name}"> <i class="el-icon-document">${name}</i> </a>
							 </span> 
							 </div>
							`;
		  div.className = "user chatbox";
		  this.$refs.view.appendChild(div);
	  }else{
        // 传递的是文本信息
		console.log( this.preMessageText);
        let div = document.createElement("div");
        div.innerHTML = `<div> <img  src="${avatar}"/>
                           <span> ${this.userMessage.message} </span> </div>`;
        div.className = "user chatbox";
        this.$refs.view.appendChild(div);
      }
      this.$refs.view.scrollTop = this.$refs.view.scrollHeight;
    },
    renderTransferDoctor(doctors) {
      let self = this;
      let view = document.getElementsByClassName("view")[1];
      let div = document.createElement("div");
      let ps = "";
      doctors.forEach(item => {
        ps += `<p uid='${item.uid}'> ${item.user_nickname} <i class='wait'> ${item.waiting_num}人排队 </i></p>`;
      });
      div.innerHTML = `<div> <img  src="${"https://ss1.bdstatic.com/70cFuXSh_Q1YnxGkpoWK1HF6hhy/it/u=1649554161,3942481746&fm=115&gp=0.jpg"}"/>
                           <span>  
                              请选择要转接的医生
                               ${ps}
                           </span> 
                        </div>`;
      div.className = "transfer chatbox";
      view.appendChild(div);
      view.scrollTop = view.scrollHeight;
      div.onclick = function(e) {
		  console.log(e)
        let uid = e.target.getAttribute("uid");
        if (uid) self.transferDoctor(+uid);
      };
    },
    transferDoctor(uid) {
      let obj = {
        method: "get_doctor_list",
        data: {
          inquiry_id: this.inquiry_id,
          doctor_uid: uid
        }
      };
      this.$store.state.sendMessage(JSON.stringify(obj));
    },
    // 放大图片处理函数
    growImg(e) {
      this.main = document.querySelector(".view .main");
      if (e.target.nodeName === "IMG") {
        this.showImgWrap = true;
        this.showImgUrl = e.target.src;
        this.main.style.zIndex = "4";
      }
    },
    closeImg() {
      this.showImgUrl = "";
      this.showImgWrap = false;
      this.main.style.zIndex = "2";
    },
    // 向医生或用户发送信息
    sendMessage(msg) {
	  let chat_message={};
      console.log(msg);
      if (msg.includes(".jpg")||msg.includes(".png")) {
        this.preImgUrl = msg;
		 chat_message = {
		  method: "chat_message",
		  data: {
		    inquiry_id: this.inquiry_id,
		    inquiry_doctor_id: this.inquiry_doctor_id,
		    to_uid: this.doctor_info.user_id,
			user:"user",
		    send_img: msg
		  }
		};
      } else if(msg.includes(".mp4")){
		  chat_message = {
		    method: "chat_message",
		    data: {
		      inquiry_id: this.inquiry_id,
		      inquiry_doctor_id: this.inquiry_doctor_id,
		      to_uid: this.doctor_info.user_id,
		  	  user:"user",
		      send_video: msg
		    }
		  };
	  } else if(msg.includes(".doc")||msg.includes(".ppt")||msg.includes(".pdf")||msg.includes(".xls")||msg.includes(".txt")){
		  chat_message = {
		    method: "chat_message",
		    data: {
		      inquiry_id: this.inquiry_id,
		      inquiry_doctor_id: this.inquiry_doctor_id,
		      to_uid: this.doctor_info.user_id,
		  	  user:"user",
		      send_file: msg
		    }
		  };
	  }else{
        this.preMessageText = msg;
		chat_message = {
		  method: "chat_message",
		  data: {
		    inquiry_id: this.inquiry_id,
		    inquiry_doctor_id: this.inquiry_doctor_id,
		    to_uid: this.doctor_info.user_id,
			user:"user",
		    send_text: msg
		  }
		};
      }
	   this.$store.state.sendMessage(JSON.stringify(chat_message));
    }
  }
};
</script>

<style lang="less" scoped>
.isfile{
	background-color: red;
	span{
		font-size: 16px !important;
	}
}
.wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  min-width: 1200px;
}
.view {
  position: absolute;
  width: 100%;
  height: calc(100% - 170px);
  border-right: 1px solid #999;
  overflow: scroll;
}

.select {
  width: 100%;
  min-width: 750px;
  height: 40px;
  background: #ddd;
  position: absolute;
  border: 1px solid #999;
  z-index: 999;
  bottom: 150px;
  width: 100%;
  img {
    width: 22px;
    height: 18px;
    margin-top: 8px;
    margin-left: 20px;
    cursor: pointer;
  }
  .uploadvideo{
	  display: inline-block;
	  position: relative;
	  overflow: hidden;
	  margin-left: 20px;
	  top: 5px;
	  input{
		  position: absolute;
		  opacity: 0;
	  }
	  span{
		  font-size: 26px;
		  color:#4D4D4D
	  }
  }
}
.select /deep/ .el-upload {
  position: absolute;
  top: -160px;
  left: 20px;
}
.select /deep/ .el-upload-list__item {
  position: absolute;
  top: -160px;
  left: 20px;
  z-index: 999;
}
.input {
  width: 100%;
  height: 150px;
  border: 1px solid #999;
  position: absolute;
  bottom: 0px;
  textarea {
    padding-top: 18px;
    width: 100%;
    height: 200px;
    font-size: 22px;
    border: 0px;
  }
  button {
    width: 100px;
    height: 45px;
    border-radius: 12px;
    font-size: 20px;
    position: absolute;
    top: 100px;
    right: 100px;
    cursor: pointer;
    outline: none;
  }
}
.chatbox {
  width: 300px;
  font-size: 30px;
  float: right;
  overflow: hidden;
  text-align: right;
  span {
    float: right;
  }
  img {
    float: right;
  }
}
.mask {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  text-align: center;
  img {
    width: 40%;
    margin-top: 100px;
  }
  .close {
    font-size: 50px;
    color: #fff;
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;
  }
}
</style>
