<template>
  <div class="view">
    <!-- <Header /> -->
    <div class="main">
      <Nav :inquiry_doctor_id="inquiry_doctor_id" :inquiry_id="inquiry_id" />
      <InputView :inquiry_doctor_id="inquiry_doctor_id" :inquiry_id="inquiry_id" />
    </div>
  </div>
</template>

<script>
import Nav from "./nav";
import InputView from "./InputView";
// import Header from "@/components/public/public_headerTop"
export default {
  components: {
    Nav,
    InputView,
    // Header
  },
  created() {
    this.inquiry_doctor_id = this.$route.query.inquiry_doctor_id;
    this.inquiry_id = this.$route.query.inquiry_id;
  }
};
</script>

<style scoped>
.main {
  position: fixed;
  z-index: 2;
  min-width: 1200px;
  width: 100%;
  top: 0px;
  height: calc(100% - 66px);
}
</style>